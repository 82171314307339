const authRoutes = {
    login: "/login",
    signUp: "/signup/{0}",
    signUp2: "/signup/2/{0}",
    cccbaSignUp: "/signup/cccba/{0}",
    peopleClerk: "/signup/peopleclerk/{0}",
    forgotPassword: "/forgotpassword",
    resetPassword: "/resetpassword",
    emailVerification: "/emailverification",
    registerAccountDetails: "/registeraccountdetails",
    loadingThirdPartyAuthentication: "/loadingthirdpartyauthentication",
    plainSignUp: "/signup",
    absoluteSignUpRoute: `https://${process.env.REACT_APP_SITE_DOMAIN}/signup`,
    absoluteLoginRoute: `https://${process.env.REACT_APP_SITE_DOMAIN}/login`,
    absoluteForgotPasswordRoute: `https://${process.env.REACT_APP_SITE_DOMAIN}/forgotpassword`,
    absoluteResetPasswordRoute: `https://${process.env.REACT_APP_SITE_DOMAIN}/resetpassword`,
};

const caseRoutes = {
    home: "/home",
    settings: "/settings",
    newCase: "/newCase",
    plaintiffDashboard: "/plaintiff/dashboard",
    plaintiffTrialDashboard: "/plaintiff/trial/dashboard",
    plaintiffGenericE2EDashboard: "/plaintiff/g/dashboard",
    plaintiffGenericE2ENonMoneyDashboard: "/plaintiff/gnm/dashboard",
    defendantDashboard: "/defendant/dashboard",
    plaintiffRoot: "/plaintiff",
    defendantRoot: "/defendant",
    plaintiffGenericE2ERoot: "/plaintiff/g",
    plaintiffGenericE2ENonMoneyRoot: "/plaintiff/gnm",
    plaintiffTrialRoot: "/plaintiff/trial",
    absoluteHomeRoute: `https://${process.env.REACT_APP_SITE_DOMAIN}/home`,
    plaintiffPeopleClerkFileRoute: "/plaintiff/pc",
    plaintiffAiDemandLetterRoot: "/plaintiff/aidemand",
    plaintiffAiDemandLetterDashboard: "/plaintiff/aidemand/dashboard",
};

const plaintiffStartRoutes = {
    introduction: "/plaintiff/start/intro",
    elements: "/plaintiff/start/elements",
    agreement: "/plaintiff/start/elements/agreement",
    performance: "/plaintiff/start/elements/performance",
    damages: "/plaintiff/start/elements/damages",
    results: "/plaintiff/start/results",
};

const plaintiffStartRoutesToRemainUnlocked = new Set([
    plaintiffStartRoutes.introduction,
    plaintiffStartRoutes.elements,
    plaintiffStartRoutes.results,
]);

const plaintiffDemandRoutes = {
    introduction: "/plaintiff/demand/intro",
    sender: "/plaintiff/demand/sender",
    recipient: "/plaintiff/demand/recipient",
    paymentInfo: "/plaintiff/demand/paymentinfo",
    deliveryMethod: "/plaintiff/demand/result",
};

const plaintiffDemandRoutesToRemainUnlocked = new Set([
    plaintiffDemandRoutes.introduction,
    plaintiffDemandRoutes.deliveryMethod,
]);

const plaintiffBuildRoutes = {
    introduction: "/plaintiff/build/intro",
    qualify: "/plaintiff/build/getstarted/qualify",
    plaintiff: "/plaintiff/build/getstarted/plaintiff",
    withinSol: "/plaintiff/build/getstarted/withinsol",
    outsideSol: "/plaintiff/build/getstarted/outsidesol",
    assess: "/plaintiff/build/assess",
    elements: "/plaintiff/build/assess/elements",
    timeline: "/plaintiff/build/assess/timeline",
    collection: "/plaintiff/build/assess/collection",
    values: "/plaintiff/build/assess/values",
    overview: "/plaintiff/build/overview",
};

const plaintiffBuildRoutesToRemainUnlocked = new Set([
    plaintiffBuildRoutes.introduction,
    plaintiffBuildRoutes.assess,
    plaintiffBuildRoutes.elements,
]);

const plaintiffFileRoutes = {
    introduction: "/plaintiff/file/intro",
    getStarted: "/plaintiff/file/getstarted",
    filingIntro: "/plaintiff/file/filingintro",
    plaintiffInfo: "/plaintiff/file/plaintiffinfo",
    defendantInfo: "/plaintiff/file/defendantinfo",
    additionalInfo: "/plaintiff/file/additionalinfo",
    sc103: "/plaintiff/file/forms/sc103",
    courthouse: "/plaintiff/file/courthouse",
    damages: "/plaintiff/file/damages",
    review: "/plaintiff/file/review",
    forms: "/plaintiff/file/forms",
    filingOptions: "/plaintiff/file/filingoptions",
    serviceOptions: "/plaintiff/file/serviceoptions",
};

const plaintiffFileRoutesToRemainUnlocked = new Set([
    plaintiffFileRoutes.introduction,
    plaintiffFileRoutes.filingIntro,
    plaintiffFileRoutes.damages,
    plaintiffFileRoutes.forms,
]);

const plaintiffPrepRoutes = {
    instructions: "/plaintiff/prep/instructions",
    introduction: "/plaintiff/prep/intro",
    statement: "/plaintiff/prep/statement",
    timeline: "/plaintiff/prep/timeline",
    damages: "/plaintiff/prep/damages",
    courtCost: "/plaintiff/prep/courtcost",
    hearingInfo: "/plaintiff/prep/caseinfo/hearinginfo",
    proofOfService: "/plaintiff/prep/caseinfo/service",
    courtForms: "/plaintiff/prep/caseinfo/upload",
    impeachment: "/plaintiff/prep/impeachment",
    review: "/plaintiff/prep/review",
};

const plaintiffTrialStartRoutes = {
    introduction: "/plaintiff/trial/start/intro",
    getStarted: "/plaintiff/trial/start/getStarted",
    plaintiffs: "/plaintiff/trial/start/plaintiffs",
    defendants: "/plaintiff/trial/start/defendants",
    caseDetails: "/plaintiff/trial/start/caseinfo/details",
    review: "/plaintiff/trial/start/review",
};

const plaintiffTrialPrepRoutes = {
    instructions: "/plaintiff/trial/prep/instructions",
    introduction: "/plaintiff/trial/prep/intro",
    coaEducation: "/plaintiff/trial/prep/coaEducation",
    statement: "/plaintiff/trial/prep/statement",
    timelineIntro: "/plaintiff/trial/prep/timelineintro",
    timeline: "/plaintiff/trial/prep/timeline",
    damages: "/plaintiff/trial/prep/damages",
    courtCost: "/plaintiff/trial/prep/courtcost",
    hearingInfo: "/plaintiff/trial/prep/caseinfo/hearinginfo",
    proofOfService: "/plaintiff/trial/prep/caseinfo/service",
    courtForms: "/plaintiff/trial/prep/caseinfo/upload",
    impeachment: "/plaintiff/trial/prep/impeachment",
    review: "/plaintiff/trial/prep/review",
};

const defendantStartRoutes = {
    introduction: "/defendant/start/intro",
    getStarted: "/defendant/start/getstarted",
    results: "/defendant/start/results",
};

const defendantAssessRoutes = {
    introduction: "/defendant/assess/intro",
    elements: "/defendant/assess/elements",
    agreement: "/defendant/assess/agreement",
    performance: "/defendant/assess/performance",
    breach: "/defendant/assess/breach",
    damages: "/defendant/assess/damages",
    review: "/defendant/assess/review",
};

const defendantSettlementRoutes = {
    introduction: "/defendant/settlement/intro",
    getStarted: "/defendant/settlement/getstarted",
    paymentPlan: "/defendant/settlement/paymentplan",
    defendant: "/defendant/settlement/defendant",
    plaintiff: "/defendant/settlement/plaintiff",
    caseInfo: "/defendant/settlement/letterinfo",
    results: "/defendant/settlement/results",
};

const defendantLawsuitRoutes = {
    introduction: "/defendant/lawsuit/intro",
    getStarted: "/defendant/lawsuit/getstarted",
    procedure: "/defendant/lawsuit/getstarted/procedure",
    checkService: "/defendant/lawsuit/proceduralchecks/service",
    checkJurisdiction: "/defendant/lawsuit/proceduralchecks/jurisdiction",
    checkVenue: "/defendant/lawsuit/proceduralchecks/venue",
    forms: "/defendant/lawsuit/forms",
    review: "/defendant/lawsuit/review",
};

const defendantPrepRoutes = {
    introduction: "/defendant/prep/intro",
    purchase: "/defendant/prep/instructions",
    statement: "/defendant/prep/statement",
    timeline: "/defendant/prep/timeline",
    paymentPlan: "/defendant/prep/paymentplan",
    damages: "/defendant/prep/costs",
    venue: "/defendant/prep/caseinfo/venue",
    hearingInfo: "/defendant/prep/caseinfo/hearinginfo",
    courtForms: "/defendant/prep/caseinfo/courtforms",
    impeachment: "/defendant/prep/impeachment",
    review: "/defendant/prep/review",
};

const plaintiffE2ERoutes = {
    start: "/plaintiff/start",
    demand: "/plaintiff/demand",
    build: "/plaintiff/build",
    file: "/plaintiff/file",
    prep: "/plaintiff/prep",
};

const plaintiffGenericE2ERoutes = {
    start: `${caseRoutes.plaintiffGenericE2ERoot}/start`,
    demand: `${caseRoutes.plaintiffGenericE2ERoot}/demand`,
};

const plaintiffGenericE2EStartRoutes = {
    introduction: `${plaintiffGenericE2ERoutes.start}/intro`,
    details: `${plaintiffGenericE2ERoutes.start}/details`,
    events: `${plaintiffGenericE2ERoutes.start}/events`,
    results: `${plaintiffGenericE2ERoutes.start}/results`,
};

const plaintiffGenericE2EStartRoutesToRemainUnlocked = new Set([
    plaintiffGenericE2EStartRoutes.introduction,
    plaintiffGenericE2EStartRoutes.results,
]);

const plaintiffGenericE2EDemandRoutes = {
    introduction: `${plaintiffGenericE2ERoutes.demand}/intro`,
    sender: `${plaintiffGenericE2ERoutes.demand}/sender`,
    recipient: `${plaintiffGenericE2ERoutes.demand}/recipient`,
    details: `${plaintiffGenericE2ERoutes.demand}/details`,
    result: `${plaintiffGenericE2ERoutes.demand}/result`,
    attorneyReviewStatus: `${plaintiffGenericE2ERoutes.demand}/attorney-review-status`,
};

const plaintiffGenericE2EDemandRoutesToRemainUnlocked = new Set([
    plaintiffGenericE2EDemandRoutes.introduction,
    plaintiffGenericE2EDemandRoutes.attorneyReviewStatus,
]);

const plaintiffTrialRoutes = {
    start: "/plaintiff/trial/start",
    prep: "/plaintiff/trial/prep",
};

const defendantE2ERoutes = {
    start: "/defendant/start",
    assess: "/defendant/assess",
    settlement: "/defendant/settlement",
    lawsuit: "/defendant/lawsuit",
    prep: "/defendant/prep",
};

const attorneyRoutes = {
    attorneyRoot: "/attorney",
    attorneyDashboard: "/attorney/dashboard",
    orderReview: "/attorney/orderreview",
    orderReviewWithId: "/attorney/orderreview/:orderId",
};

const plaintiffGenericE2ENonMoneyRoutes = {
    start: `${caseRoutes.plaintiffGenericE2ENonMoneyRoot}/start`,
    demand: `${caseRoutes.plaintiffGenericE2ENonMoneyRoot}/demand`,
};

const plaintiffGenericE2ENonMoneyStartRoutes = {
    introduction: `${plaintiffGenericE2ENonMoneyRoutes.start}/intro`,
    details: `${plaintiffGenericE2ENonMoneyRoutes.start}/details`,
    events: `${plaintiffGenericE2ENonMoneyRoutes.start}/events`,
    results: `${plaintiffGenericE2ENonMoneyRoutes.start}/results`,
};

const plaintiffGenericE2ENonMoneyStartRoutesToRemainUnlocked = new Set([
    plaintiffGenericE2ENonMoneyStartRoutes.introduction,
    plaintiffGenericE2ENonMoneyStartRoutes.results,
]);

const plaintiffGenericE2ENonMoneyDemandRoutes = {
    introduction: `${plaintiffGenericE2ENonMoneyRoutes.demand}/intro`,
    sender: `${plaintiffGenericE2ENonMoneyRoutes.demand}/sender`,
    recipient: `${plaintiffGenericE2ENonMoneyRoutes.demand}/recipient`,
    details: `${plaintiffGenericE2ENonMoneyRoutes.demand}/details`,
    result: `${plaintiffGenericE2ENonMoneyRoutes.demand}/result`,
    attorneyReviewStatus: `${plaintiffGenericE2ENonMoneyRoutes.demand}/attorney-review-status`,
};

const plaintiffGenericE2ENonMoneyDemandRoutesToRemainUnlocked = new Set([
    plaintiffGenericE2ENonMoneyDemandRoutes.introduction,
    plaintiffGenericE2ENonMoneyDemandRoutes.attorneyReviewStatus,
]);

const plaintiffAiDemandLetterRoutes = {
    start: `${caseRoutes.plaintiffAiDemandLetterRoot}/start`,
    demand: `${caseRoutes.plaintiffAiDemandLetterRoot}/demand`,
};

const plaintiffAiDemandLetterStartRoutes = {
    introduction: `${plaintiffAiDemandLetterRoutes.start}/intro`,
    disputeInfo: `${plaintiffAiDemandLetterRoutes.start}/disputeInfo`,
};

const plaintiffAiDemandLetterDemandRoutes = {
    introduction: `${plaintiffAiDemandLetterRoutes.demand}/intro`,
    details: `${plaintiffAiDemandLetterRoutes.demand}/details`,
    result: `${plaintiffAiDemandLetterRoutes.demand}/result`,
};

const plaintiffPeopleClerkFileRoutes = {
    loading: `${caseRoutes.plaintiffPeopleClerkFileRoute}/loading`,
};

export {
    authRoutes,
    caseRoutes,
    plaintiffStartRoutes,
    plaintiffStartRoutesToRemainUnlocked,
    plaintiffBuildRoutes,
    plaintiffBuildRoutesToRemainUnlocked,
    defendantStartRoutes,
    defendantAssessRoutes,
    defendantSettlementRoutes,
    defendantLawsuitRoutes,
    defendantPrepRoutes,
    plaintiffDemandRoutes,
    plaintiffDemandRoutesToRemainUnlocked,
    plaintiffFileRoutes,
    plaintiffFileRoutesToRemainUnlocked,
    plaintiffPrepRoutes,
    plaintiffE2ERoutes,
    plaintiffTrialRoutes,
    defendantE2ERoutes,
    attorneyRoutes,
    plaintiffTrialStartRoutes,
    plaintiffTrialPrepRoutes,
    plaintiffGenericE2ERoutes,
    plaintiffGenericE2EStartRoutes,
    plaintiffGenericE2EStartRoutesToRemainUnlocked,
    plaintiffGenericE2EDemandRoutes,
    plaintiffGenericE2EDemandRoutesToRemainUnlocked,
    plaintiffGenericE2ENonMoneyRoutes,
    plaintiffGenericE2ENonMoneyStartRoutes,
    plaintiffGenericE2ENonMoneyStartRoutesToRemainUnlocked,
    plaintiffGenericE2ENonMoneyDemandRoutes,
    plaintiffGenericE2ENonMoneyDemandRoutesToRemainUnlocked,
    plaintiffAiDemandLetterRoutes,
    plaintiffAiDemandLetterStartRoutes,
    plaintiffAiDemandLetterDemandRoutes,
    plaintiffPeopleClerkFileRoutes,
};
